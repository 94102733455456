
import "./App.css";
import { Button } from "antd";
import Navbar from "./components/Navbar";
import { Route, Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { PdfTools } from "./content/PdfTools";
import ConvertFilesPDF from "./pages/ConvertFiles";
import ConvertFilesImages from "./pages/ConvertFilesImages";
import { ImageTools } from "./content/ImageTools";
function App() {
  return (
   
    <Routes>

      <Route path="/" Component={Home} />
      {/* <Route path="/convert" Component={ConvertFiles} /> */}
      {
        PdfTools.map((item)=><Route path={item.name} Component={ConvertFilesPDF}/>)

      }
        {/* <Route path="/convert" Component={ConvertFiles} /> */}
        {
        ImageTools.map((item)=><Route path={item.name} Component={ConvertFilesImages}/>)

      }
    </Routes>
   
  );
}

export default App;
