import React from "react";
import Navbar from "../components/Navbar";
import { PdfTools } from "../content/PdfTools";
import { ImageTools } from "../content/ImageTools";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="w-screen ">
      {/* <Navbar/> */}

      <div className=" w-full  h-full flex-col md:px-16 px-6">
        <section id="title">
          <div className="w-full py-3 gap-3">
            {/* <img className="h-32 bg-cover containe" src={require("../assets/IMAGETOPDF.jpg")}/> */}

            {/* <h1 className="font-bold text-4xl text-center leading-10 py-3 text-[50px]">
              <span className="text-blue-800 font-semibold italic tracking-wider text-5xl">Welcome to,<br/></span>
              AFOXLIFE
            </h1> */}
            <h1 className="font-bold text-sm md:text-4xl text-center  py-3 capitalize">
              Every tool you need to work with PDFs/Images in one place
            </h1>
            <center>
              <h1 className="text-center text-xs md:line-clamp-2 md:w-2/3 md:leading-7 md:text-xl pb-3 centered-containers capitalize">
                Access free online PDF tools for conversion, compression,
                repair, and editing – fast, secure, and easy-to-use solutions
                for all your PDF needs
              </h1>
            </center>
          </div>
        </section>
        <section
          id="tools "
          className="flex items-center flex-col w-full justify-center"
        >
          <h1 className="font-bold text-xl md:text-4xl my-9">PDF TOOLS</h1>

          <div className="my-2 md:my-5 grid md:grid-cols-3 grid-cols-1 sm:grid-cols-2 gap-2 md:gap-4 items-center justify-center md:w-[80%] ">
            {PdfTools.map((item) => {
              return (
                <button
                  onClick={() => navigate(item.name)}
                  className="flex flex-col items-center w-full mt-4 md:mt-1 gap-3   hover:scale-105 bg-white md:p-5 p-4 rounded-md md:flex-row"
                >
                  <img className="size-20 md:size-28 rounded-sm" src={item.icon} />
                  <div>
                    <p className=" line-clamp-1 text-sm text-center font-bold md:text-[16px] mb-2">
                      {item.title}
                    </p>
                    <p className="md:text-xs sm:line-clamp-2 md:line-clamp-4 line-clamp-4 text-xs capitalize">
                      {item?.description}
                    </p>
                  </div>
                </button>
              );
            })}
          </div>

          <h1 className="font-bold text-xl md:text-4xl my-9">Image Tools</h1>
          <div className="my-5 grid md:grid-cols-3 grid-cols-1 md:gap-5 items-center justify-center w-full md:w-[80%]">
            {ImageTools.map((item) => {
              return (
                <button
                onClick={() => navigate(item.name)}
                className="flex flex-col items-center w-full mt-4 md:mt-1 gap-3   hover:scale-105 bg-white md:p-5 p-4 rounded-md md:flex-row"
              >
                <img className="size-20 md:size-28 rounded-sm" src={item.icon} />
                <div>
                  <p className=" line-clamp-1 text-sm text-center font-bold md:text-[16px] mb-2">
                    {item.title}
                  </p>
                  <p className="md:text-xs sm:line-clamp-2 md:line-clamp-4 line-clamp-4 text-xs capitalize">
                    {item?.description}
                  </p>
                </div>
              </button>
              );
            })}
          </div>
        </section>
      </div>

      <div className="centered-container">{/* <Footer/> */}</div>
    </div>
  );
};

export default Home;
