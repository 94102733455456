import React, { useState } from "react";
import { Layout, Menu, Dropdown, Input, Button, Drawer } from "antd";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import { PdfTools, PdfToolsTitles } from "../content/PdfTools";
import ToolsTabs from "./ToolsTabs";
import { ImageToolsTitles } from "../content/ImageTools";
import { useNavigate } from "react-router-dom";
// import 'antd/dist/antd.css';

const { Header } = Layout;

const Navbar = () => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const menuPDF = (
    <Menu style={{ backgroundColor: "whitesmoke" }}>
      <div className="md:grid grid-cols-3 gap-9 p-9">
        {PdfToolsTitles?.map((item) => (
          <ToolsTabs key={item?.name} item={item} isPDF={true} />
        ))}
      </div>
    </Menu>
  );
  const menuImage = (
    <Menu style={{ backgroundColor: "whitesmoke" }}>
      <div className="md:grid grid-cols-2 gap-9 p-9">
        {ImageToolsTitles?.map((item) => (
          <ToolsTabs key={item?.name} item={item} />
        ))}
      </div>
    </Menu>
  );
  const navigate = useNavigate();
  return (
    <div className="sticky  overflow-auto flex items-center justify-between  md:px-16 px-6 py-2  w-screen ">
      {/* Logo */}

      <div className="flex items-center justify-between w-[40%]">
        <div
          onClick={() => navigate("/")}
          className="text-blue-700 text-3xl font-extrabold cursor-pointer"
        >
          AFOXLIFE
        </div>
        <div className="hidden md:flex gap-10 uppercase">
          <Dropdown overlay={menuPDF} trigger={["hover"]}>
            <a
              className="text-blue-700 hover:text-gray-300"
              onClick={(e) => e.preventDefault()}
            >
              All Pdf Tools
            </a>
          </Dropdown>
          <Dropdown overlay={menuImage} trigger={["hover"]}>
            <a
              className="text-blue-700 hover:text-gray-300 uppercase flex-col"
              onClick={(e) => e.preventDefault()}
            >
              All Image Tools
            </a>
          </Dropdown>
        </div>
      </div>
      {/* Navigation Links and Search (hidden on small screens) */}

      {/* Menu Button (visible on small screens) */}
      <Button
        className=" block md:hidden  text-blue-800 border-4"
        onClick={showDrawer}
        icon={<MenuOutlined />}
        type="text"
      />

      {/* Drawer for Mobile Menu */}
      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        visible={visible}
        className="md:hidden "
      >
        <Menu mode="vertical" className="space-y-4">
          {/* <Dropdown overlay={menu} trigger={["click"]}>
            <a className="text-black" onClick={(e) => e.preventDefault()}>
              Links <DownOutlined />
            </a>
          </Dropdown> */}
          {/* <Input.Search placeholder="Search..." className="w-full" /> */}
          <div>
            <Dropdown overlay={menuPDF} trigger={["hover"]}>
              <Menu.Item style={{ backgroundColor: "white" }}>
                <a
                  className="text-blue-700 hover:text-gray-300 text-center text-xl"
                  onClick={(e) => e.preventDefault()}
                >
                  All PDF TOOLS
                </a>
              </Menu.Item>
            </Dropdown>
          </div>
          <div>
            <Dropdown overlay={menuImage} trigger={["hover"]}>
              <Menu.Item style={{ backgroundColor: "white" }}>
                <a
                  className="text-blue-700 hover:text-gray-300 uppercase flex-col text-center text-xl"
                  onClick={(e) => e.preventDefault()}
                >
                  All Image Tools
                </a>
              </Menu.Item>
            </Dropdown>
          </div>
        </Menu>
      </Drawer>
    </div>
  );
};

export default Navbar;
