import React from 'react'
import { useLocation } from 'react-router-dom'
import Navbar from './components/Navbar'
import App from './App'
import Footer from './components/Footer'

const Page = () => {
    const location=useLocation()
    // alert(location.pathname)
    console.log(location);
    
  return (
    <>
    <div className="w-full top-0 sticky  bg-white overflow-hidden">
        <Navbar />
      </div>
      <div className="w-full  bg-[#f5f5fa] overflow-x-hidden h-full">
        <App />
      </div>
      {location.pathname==='/'&&
        <div className="w-full centered-container bg-white overflow-hidden">
          <Footer />
        </div>
      }
    </>
  )
}

export default Page