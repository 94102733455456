import React, { useRef, useState } from "react";
import Navbar from "../components/Navbar";
import { useLocation } from "react-router-dom";
import { PdfTools } from "../content/PdfTools";
import { Button, Spin } from "antd";

import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ImageTools } from "../content/ImageTools";
const ConvertFilesImages = () => {
  const location = useLocation();
  const pathname = location.pathname.slice(1);
  const findTool = ImageTools.find((item) => item.name == pathname);
  const pdfFileUpload = useRef(null);
  const [loading, setloading] = useState(false);
  const [visibleActive, setvisibleActive] = useState(false);
  const [setdownloadUrl, setsetdownloadUrl] = useState("");
  const [imageData, setimageData] = useState([]);
  //   console.log(findTool);
  const handleFileUpload = () => {
    pdfFileUpload.current.click();
  };
  const handleOnDragEnd = (result) => {
    if (!result.destination) return; // If dropped outside a valid area

    const reorderedImages = Array.from(imageData);
    const [movedImage] = reorderedImages.splice(result.source.index, 1); // Remove item
    reorderedImages.splice(result.destination.index, 0, movedImage); // Add at new position

    setimageData(reorderedImages);
  };
  const handleProcessfile = async (e, isMultiple) => {
    setloading(true);
    const file = isMultiple ? e.target.files : e.target.files[0];
    if (!file) {
      alert("Please select a Word document first.");
      setloading(false)
      return;
    }
    if (isMultiple) {
      const files = Array.from(file);
      const newImages = files.map((file, index) => ({
        id: `${file.name}-${Math.random() * 200}`, // Unique ID for DnD
        file,
        preview: URL.createObjectURL(file),
      }));
      setItems((prev) => [...newImages]);
      setvisibleActive(true);
    
      
      setloading(false);
      return;
    }

    const find = await findTool.function(file);
    if (find) {
      setsetdownloadUrl(find);
    }
    setloading(false);
  };
  const downloadPDF = () => {
    // const blob = new Blob([setdownloadUrl], { type: 'application/pdf' });
    const downloadLink = document.getElementById("downloadLink");
    downloadLink.href = setdownloadUrl;
    downloadLink.download = `converted.${findTool.output_format?findTool.output_format:'png'}`;
    downloadLink.style.display = "inline-block";
    // downloadLink.textContent = "Download PDF";
    downloadLink.click();
  };
  const ImageItem = ({ id, src }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: "grab",
    };

    return (
      <div
        ref={setNodeRef}
        className="w-[300px] h-[300px] m-3"
        style={style}
        {...attributes}
        {...listeners}
      >
        <img src={src} className="w-[300px] h-[300px]" />
      </div>
    );
  };

  const [items, setItems] = useState([]);

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };
  if (visibleActive) {
    return (
      <>
        <a id="downloadLink" />
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={items.map((item) => item.id)}
            strategy={rectSortingStrategy}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                justifyContent: "center",
                marginTop: 100,

                width: "100%",
                alignItems: "center",
              }}
            >
              <div className=" flex xl:flex-row flex-col items-center justify-center bg-gray-50-200 p-10 shadow-amber-100 border-red-900  ">
                {items.map((item) => (
                  <ImageItem key={item.id} id={item.id} src={item.preview} />
                ))}
              </div>
            </div>
          </SortableContext>
        </DndContext>
        <center>
          {loading && <Spin />}

          {setdownloadUrl.length > 0 ? (
            <Button
              onClick={downloadPDF}
              className="bg-blue-500 text-white text-3xl py-10 px-20 rounded-lg mt-10 font-medium"
            >
              Download File
            </Button>
          ) : (
            <Button
              onClick={async (e) => {
              
                
                setloading(true)
                const files=items?.map((item)=>item?.file)

                const find = await findTool.function(files);
                if(find){
                  setloading(false)
                  
                  setsetdownloadUrl(find)
                  setvisibleActive(false)
                }else{
                  setloading(false)
                }

              }}
              className={`bg-blue-500 text-white text-3xl py-10 px-20 rounded-lg mt-10 font-medium ${
                loading && "hidden"
              }`}
            >
              Convert
            </Button>
          )}
        </center>
      </>
    );
  }
  return (
    <div className="w-screen flex-col justify-center h-screen">
      <a id="downloadLink" />
      <h1 className="font-bold text-4xl text-center leading-10 py-3">
        {findTool?.title}
      </h1>
      <h1 className="text-center md:line-clamp-2  leading-7 text-2xl pb-3">
        {findTool.description}
      </h1>
      <input
        onChange={(e) => handleProcessfile(e, findTool.isMultiple)}
        ref={pdfFileUpload}
        className="hidden"
        type="file"
        multiple={findTool.isMultiple}
        accept={findTool.file_types}
      />
      <center>
        {loading && <Spin />}

        {setdownloadUrl.length > 0 ? (
          <Button
            onClick={downloadPDF}
            className="bg-blue-500 text-white text-3xl py-10 px-20 rounded-lg mt-10 font-medium "
          >
            Download File
          </Button>
        ) : (
          <Button
            onClick={(e) => handleFileUpload()}
            className={`bg-blue-500 text-white text-3xl py-10 px-20 rounded-lg mt-10 font-medium w-[80%] md:w-[400px] ${
              loading && "hidden"
            }`}
          >
            {findTool.button_title}
          </Button>
        )}
      </center>
    </div>
  );
};

export default ConvertFilesImages;
