// import docxtopdf from "docx-pdf";

// import officeToPdf from "office-to-pdf";
import React, { useState } from "react";
import { jsPDF } from "jspdf";

// import officegen from 'pdf-officegen'
// import * as pdfjsLib from "pdfjs-dist";
// import officegen from "pdf-officegen";
import * as pdfjsLib from 'pdfjs-dist';
import htmlDocx from 'html-docx-js/dist/html-docx';
import pdfToText from "react-pdftotext";
import * as XLSX from "xlsx";
import { PDFDocument, PDFImage } from "pdf-lib";
import axios from "axios";

const parsePdf = async (file) => {
  try {
    // Step 1: Read the PDF file as an array buffer
    const arrayBuffer = await file.arrayBuffer();

    // Step 2: Extract the text content from the PDF
    const text = await pdfToText(new Uint8Array(arrayBuffer));

    // Step 3: Split the extracted text by the form-feed character to separate pages
    const pages = text.split("\f");
    const excelData = [["Page Number", "Line Content"]];

    // Process each page and its lines
    // pages.forEach((content, pageIndex) => {
    //   const lines = content.split("\n").filter((line) => line.trim()); // Split into lines
    //   if (lines.length > 0) {
    //     lines.forEach((line) => {
    //       excelData.push([`Page ${pageIndex + 1}`, line]); // Add page and line content
    //     });
    //   }
    // });
    // Step 4: Return an array of page objects, each containing the page number and its lines
    return excelData
  } catch (error) {
    console.error("Error parsing PDF:", error);
    throw error; // You can handle this error as needed
  }
};

// import * as htmlDocx from "html-docx-js";
export const pdf_to_word = async (file) => {
  try {
    const uri='https://pythonbackend.vercel.app/convert/pdf-to-word/'
    const form=new FormData()
    form.append('file',file)
    const pdfData=await axios.postForm(uri,form)
    return pdfData.data
  } catch (error) {
    console.log(error);
    
  }
};
export const pdf_to_powerpoint = async (file) => {
  try {
    const uri='https://pythonbackend.vercel.app/convert/pdf-to-ppt/'
    const form=new FormData()
    form.append('file',file)
    const pdfData=await axios.postForm(uri,form)
    return pdfData.data
  } catch (error) {
    console.log(error);
    
  }
};
export const jpg_to_pdf = async (file) => {
  try {
    const pdf = new jsPDF();
    const addImageToPdf = (pdf, img, pageWidth, pageHeight) => {
      const ratio = Math.min(pageWidth / img.width, pageHeight / img.height);

      const imgWidth = img.width * ratio;
      const imgHeight = img.height * ratio;

      const x = (pageWidth - imgWidth) / 2;
      const y = (pageHeight - imgHeight) / 2;

      pdf.addImage(img, "JPEG", x, y, imgWidth, imgHeight, undefined, "MEDIUM");
    };

    for (let i = 0; i < file.length; i++) {
      const imgFile = file[i];

      if (!imgFile.type.startsWith("image/")) {
        throw new Error(`Invalid file type: ${imgFile.name}`);
      }

      const img = new Image();

      await new Promise((resolve, reject) => {
        img.onload = () => {
          try {
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            if (i > 0) {
              pdf.addPage();
            }
            addImageToPdf(pdf, img, pageWidth, pageHeight);
            resolve();
          } catch (error) {
            reject(error);
          }
        };

        img.onerror = () =>
          reject(new Error(`Failed to load image: ${imgFile.name}`));
        img.src = URL.createObjectURL(imgFile);
      });
    }

    // Generate a Blob URL for the PDF and return it
    const pdfBlob = pdf.output("blob");
    return URL.createObjectURL(pdfBlob);
  } catch (error) {
    alert(error);
  }
};

export const word_to_pdf = async (file) => {
  try {
  } catch (error) {
    console.error("Error extracting text:", error);
    alert("There was an error processing the file.");
  }
};
const parseExcel = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]; // Read the first sheet
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Get data as a 2D array
      resolve(jsonData);
    };
    reader.onerror = (err) => reject(err);
    reader.readAsArrayBuffer(file);
  });
};
export const excel_to_pdf = async (file) => {
  const data = await parseExcel(file);
  const doc = new jsPDF();
  let y = 10; // Starting Y position

  doc.setFontSize(12);
  doc.text("Excel Data", 10, y);
  y += 10;

  data.forEach((row, index) => {
    const rowText = row.join(" | ");
    doc.text(`${index + 1}. ${rowText}`, 10, y);
    y += 10;

    // Create a new page if the content exceeds the page length
    if (y > 280) {
      doc.addPage();
      y = 10;
    }
  });

  const pdfBlob = doc.output("blob");
  return URL.createObjectURL(pdfBlob);
};
export const pdf_to_excel = async (file) => {
  
  
  const parsedata = await parsePdf(file);
  
  
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(parsedata);

  XLSX.utils.book_append_sheet(workbook, worksheet, "PDF Data");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const excelBlob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  return URL.createObjectURL(excelBlob);
};

// Function to compress PDF by reducing image quality
export const compress_pdf = async (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Read the PDF file
      const arrayBuffer = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Loop through each page in the PDF
      const pages = pdfDoc.getPages();
      for (const page of pages) {
        const resources = page.node.Resources;

        // If the page has images, we'll try to compress them
        if (resources?.XObject) {
          for (const xObjectKey in resources.XObject) {
            const xObject = resources.XObject[xObjectKey];

            // If the object is an image
            if (xObject instanceof PDFImage) {
              // Get image bytes and embed a new compressed version
              const originalImageBytes = await xObject.bytes();
              const image = await pdfDoc.embedPng(originalImageBytes);

              // Resize the image to reduce quality and save it back to the page
              const resizedImage = await pdfDoc.embedPng(image.bytes()); // Resizing is done here (implement further resizing logic if necessary)
              resources.XObject[xObjectKey] = resizedImage;
            }
          }
        }
      }

      // Optimize the PDF by removing unused objects
      await pdfDoc.flush(); // This ensures the PDF is cleaned up

      // Save the PDF after compression
      const compressedPdfBytes = await pdfDoc.save();
      const compressedPdfBlob = new Blob([compressedPdfBytes], {
        type: "application/pdf",
      });
      const compressedPdfUrl = URL.createObjectURL(compressedPdfBlob);

      resolve(compressedPdfUrl);
    } catch (error) {
      reject("Error compressing PDF: " + error);
    }
  });
};
