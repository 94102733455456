import Compressor from "compressorjs";
import { removeBackground } from "@imgly/background-removal";

export const compress_img = async (file) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.8, // You can adjust this value as needed
      success: (compressedResult) => {
        // When the image is compressed, process the result
        compressedResult
          .arrayBuffer()
          .then((buffer) => {
            // Create an object URL for the compressed image
            const imaged = URL.createObjectURL(new Blob([buffer]));
            resolve(imaged); // Return the image URL once ready
          })
          .catch((error) => {
            reject(error); // Handle any errors during arrayBuffer processing
          });
      },
      error: (error) => {
        reject(error); // Handle any errors during compression
      },
    });
  });
};
export const remove_bg_img = async (file) => {
  const url = await removeBackground(file, {
    output: { format: "image/png", type: "mask" ,quality:0.5},
    progress:(key,current,total)=>{
        console.log(`Downloading ${key}: ${current} of ${total}`);
    }
  });
  const fileUrl = URL.createObjectURL(url);

  return fileUrl;
};
export const convert_to_jpg = (file) => {
    return new Promise((resolve, reject) => {
      // Create an image element
      const img = new Image();
  
      // Set the image source to the file object URL
      const objectURL = URL.createObjectURL(file);
      img.src = objectURL;
  
      // Wait for the image to load
      img.onload = () => {
        // Create a canvas element
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        // Set the canvas dimensions to the image's width and height
        canvas.width = img.width;
        canvas.height = img.height;
  
        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);
  
        // Convert the canvas content to a JPG (quality 0.8)
        const jpgDataUrl = canvas.toDataURL('image/jpeg', 0.8);
  
        // Resolve with the JPG Data URL
        resolve(jpgDataUrl);
  
        // Release the object URL
        URL.revokeObjectURL(objectURL);
      };
  
      // Handle image loading errors
      img.onerror = (error) => {
        reject('Error converting image to JPG: ' + error);
      };
    });
  };
  
