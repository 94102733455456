import React from "react";
import { useNavigate } from "react-router-dom";
import { PdfTools } from "../content/PdfTools";
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  MailFilled,
  TwitterCircleFilled,
} from "@ant-design/icons";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="flex-col px-11 items-center justify-between bg-white w-full ">
      <section className="py-[51px]">
        <div className=" grid grid-cols-1 sm:grid-cols-2 w-full h-full md:flex  gap-8 text-white sm:justify-between   xl:flex-wrap  xl:justify-between  xl:gap-10">
          <div className="md:w-[370px]">
            <div
              onClick={() => navigate("/")}
              className="text-blue-700 text-3xl font-extrabold cursor-pointer"
            >
              AFOXLIFE
            </div>
            <p className="text-black text-[16px] leading-[24px] my-[25px]">
              Access free online PDF tools for conversion, compression, repair,
              and editing – fast, secure, and easy-to-use solutions for all your
              PDF needsx
            </p>
            <ul className="list-none flex items-center gap-4">
              <li>
                <a href="https://www.instagram.com/afoxlife7/?__pwa=">
                  <InstagramFilled className="text-[20px] cursor-pointer text-blue-600  transition-all duration-100 ease-linear hover:text-gray-400" />
                </a>{" "}
                {/* <LinkedInIcon className="text-[18px] cursor-pointer  transition-all duration-100 ease-linear hover:text-gray-400" /> */}
              </li>
              <li>
                <a href="https://x.com/afoxlife7">
                  <TwitterCircleFilled className="text-[20px] cursor-pointer text-blue-600  transition-all duration-100 ease-linear hover:text-gray-400" />
                </a>
                {/* <TwitterIcon className="text-[18px] cursor-pointer  transition-all duration-100 ease-linear hover:text-gray-400" /> */}
              </li>
              <li>
                <a href="https://www.linkedin.com/in/a-fox-life-online-free-tools">
                  <LinkedinFilled className="text-[20px] cursor-pointer text-blue-600  transition-all duration-100 ease-linear hover:text-gray-400" />
                </a>{" "}
                {/* <FacebookIcon className="text-[18px] cursor-pointer  transition-all duration-100 ease-linear hover:text-gray-400" /> */}
              </li>
              <li>
                <a href="mailto:afoxlife7@gmail.com">
                  <MailFilled className="text-[20px] cursor-pointer text-blue-600 transition-all duration-100 ease-linear hover:text-gray-400" />
                </a>{" "}
              </li>
              <li>
                {/* <YouTubeIcon className="text-[18px] cursor-pointer  transition-all duration-100 ease-linear hover:text-gray-400" /> */}
              </li>
            </ul>
          </div>
          <div>
            <h2 className="font-bold mb-[22px] text-blue-500">Company</h2>
            <ul className="flex flex-col gap-[15px] text-[16px]  leading-[24px] text-black ">
              <li className=" hover:underline font-medium transition-all delay-150 ease-linear cursor-pointer">
                About us
              </li>
              <li className=" hover:underline font-medium transition-all delay-150 ease-linear cursor-pointer">
                Blogs
              </li>
              <li className=" hover:underline font-medium transition-all delay-150 ease-linear cursor-pointer">
                FAQ&apos;s
              </li>
              <li className=" hover:underline font-medium transition-all delay-150 ease-linear cursor-pointer">
                Contact
              </li>
              <li className=" hover:underline font-medium transition-all delay-150 ease-linear cursor-pointer">
                Privacy Policy
              </li>
            </ul>
          </div>
          <div className="">
            <h2 className="font-semibold mb-[22px] text-blue-500">PDF TOOLS</h2>
            <ul className="flex flex-col gap-[15px] text-[16px] text-black leading-[24px]">
              <li className=" hover:underline font-medium transition-all delay-150 ease-linear cursor-pointer">
                <a href="/jpg_to_pdf">JPG TO PDF</a>
              </li>
              <li className=" hover:underline font-medium transition-all delay-150 ease-linear cursor-pointer">
                <a href="/pdf_to_word">PDF TO WORD'</a>
              </li>
              <li className=" hover:underline font-medium transition-all delay-150 ease-linear cursor-pointer">
                <a href="/pdf_to_powerpoint">PDF TO PPT</a>
              </li>
              <li className=" hover:underline font-medium transition-all delay-150 ease-linear cursor-pointer">
                <a href="/pdf_to_excel">PDF TO EXCEL</a>
              </li>
            </ul>
          </div>
          <div className="">
            <h2 className="font-semibold mb-[22px] text-blue-500">
              IMAGE TOOLS
            </h2>
            <ul className="flex flex-col gap-[15px] text-[16px] text-black leading-[24px]">
              <li className=" hover:underline font-medium transition-all delay-150 ease-linear cursor-pointer">
                <a href="/convert_to_jpg">PNG TO JPG</a>
              </li>
              <li className=" hover:underline font-medium transition-all delay-150 ease-linear cursor-pointer">
                <a href="/compress_img">Compress Image</a>
              </li>
              <li className=" hover:underline font-medium transition-all delay-150 ease-linear cursor-pointer">
                <a href="/remove_bg_img">Remove Background</a>
              </li>
            </ul>
          </div>
          <div className="">
            <h2 className="font-semibold mb-[22px] text-blue-500">Contact</h2>
            <ul className="flex flex-col gap-[15px] text-[16px] text-black leading-[24px]">
              <a
                href="mailto:afoxlife7@gmail.com"
                className="hover:underline cursor-pointer"
              >
                support@afoxlife.com
              </a>
            </ul>
          </div>
        </div>
      </section>
      <section className="w-[100%] h-[88px]">
        <div className="h-full w-full  text-[16px] text-[#888787] flex items-center justify-center text-center">
          <p>© 2024 AFOXLIFE. All Right Reserved.</p>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
