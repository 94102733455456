import { compress_pdf, excel_to_pdf, jpg_to_pdf, pdf_to_excel, pdf_to_powerpoint, pdf_to_word, word_to_pdf } from "../methods/pdfController";
export const PdfTools = [
  {
    name: "word_to_pdf",
    icon: require('../assets/word_to_pdf.png'),
    title: "Word To PDF",
    description: "Convert Word to PDF instantly online - fast, secure, and easy-to-use.",
    file_types: ".doc, .docx",
    tool_type: "convert_to_pdf",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
    button_title:'Select Word File',
    isMultiple:false
  },
  {
    name: "powerpoint_to_pdf",
    icon: require('../assets/powerpoint_to_pdf.png'),
    title: "POWERPOINT To PDF",
    description: "Convert PPT to PDF quickly online - easy, secure, and high-quality results.",
    file_types: "",
    tool_type: "convert_to_pdf",
    button_title:'Select Powerpoint File',
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },
  {
    name: "excel_to_pdf",
    icon: require('../assets/excel_to_pdf.png'),
    title: "EXCEL To PDF",
    description: "Convert Excel to PDF online effortlessly - secure, fast, and delivers high-quality formatted results.",
    file_types: ".xls .xlsx",
    tool_type: "convert_to_pdf",
    enable_file_types: "",
    button_title:'Select Excel File',
    function: (e) => excel_to_pdf(e),
  },
  {
    name: "html_to_pdf",
    icon: require('../assets/html_to_pdf.png'),
    title: "HTML To PDF",
    description: "Convert HTML to PDF easily online - fast, secure, and retains original formatting perfectly.",
    file_types: "",
    tool_type: "convert_to_pdf",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },

  {
    name: "pdf_to_word",
    icon: require('../assets/pdf_to_word.png'),
    title: "PDF To Word ",
    description: "Convert PDF to Word seamlessly online - fast, secure, and maintains original formatting accuracy",
    file_types: ".pdf",
    tool_type: "convert_from_pdf",
    enable_file_types: "",
    output_format:'docx',
    button_title:"Select Pdf file",
    function: (e) => pdf_to_word(e),
  },
  {
    name: "pdf_to_powerpoint",
    icon: require('../assets/pdf_to_powerpoint.png'),
    title: "PDF To POWERPOINT ",
    description: "Convert PDF to PPT effortlessly online - fast, reliable, and preserves original slide layouts.",
    file_types: ".pdf",
    tool_type: "convert_from_pdf",
    enable_file_types: "",
    output_format:'pptx',
    button_title:"Select Pdf file",
    function: (e) => pdf_to_powerpoint(e),
  },
  {
    name: "pdf_to_excel",
    icon: require('../assets/pdf_to_excel.png'),
    title: "PDF To EXCEL ",
    description: "Convert PDF to Excel accurately online – fast, secure, and maintains original data accuracy.",
    file_types: "",
    output_format:'xlsx',
    tool_type: "convert_from_pdf",
    enable_file_types: "",
    button_title:'Select pdf File',
    function: (e) => pdf_to_excel(e),
  },
  // {
  //   name: "pdf_to_jpg",
  //   icon: "",
  //   title: "PDF To JPG ",
  //   description: "",
  //   file_types: "",
  //   tool_type: "convert_from_pdf",
  //   enable_file_types: "",
  //   function: (e) => word_to_pdf(e),
  // },
   {
    name: "jpg_to_pdf",
    icon: require('../assets/jpg_to_pdf.png'),
  
    title: "JPG To PDF",
    description: "Convert JPG to PDF instantly online – fast, secure, and preserves image quality perfectly.",
    file_types: "",
    tool_type: "convert_to_pdf",
    enable_file_types: "",
     function: (e) => jpg_to_pdf(e),
    button_title:'Select Image Files',
    isMultiple:true
  },

  {
    name: "compress_pdf",
    icon: require('../assets/compress_pdf.png'),
    title: "Compress PDF",
    description: "Compress PDF files online effortlessly – reduce size while maintaining high quality and security.",
    file_types: "",
    tool_type: "optimize_pdf",
    enable_file_types: "",
    button_title:'Select Pdf File',
    function: (e) => compress_pdf(e),
  },
  {
    name: "repair_pdf",
    icon: require('../assets/repair_pdf.png'),
    title: "REPAIR PDF",
    description: "Repair corrupted PDF files online quickly – restore content and formatting with ease and security.",
    file_types: "",
    tool_type: "optimize_pdf",
    enable_file_types: "",
    button_title:'Select Pdf File',
    function: (e) => compress_pdf(e),
  },
];

export const PdfToolsTitles = [
  {
    name: "CONVERT TO PDF",
    slug: "convert_to_pdf",
  },
  {
    name: "CONVERT FROM PDF",
    slug: "convert_from_pdf",
  },
  {
    name: "OPTIMIZE PDF",
    slug: "optimize_pdf",
  },
];
