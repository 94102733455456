import { compress_img, convert_to_jpg, remove_bg_img } from "../methods/imageController";
import { pdf_to_word, word_to_pdf } from "../methods/pdfController";
export const ImageTools = [
  {
    name: "compress_img",
    icon: require('../assets/compress_img.jpeg'),
    title: "Compress Image",
    description: "Free Compress image Countless time with Essential Quality for any img file (pag,jpg,etc...) Easy and Fast For Any OS -AFoxlife. Click Now",
    file_types: "",
    tool_type: "optimize_img",
    enable_file_types: "",
    button_title:'Select Image File',
    function: (e) => compress_img(e),
  },
  {
    name: "remove_bg_img",
    icon: require('../assets/remove_bg_img.jpeg'),
    title: "Remove background",
    description: "",
    file_types: "",
    tool_type: "optimize_img",
    enable_file_types: "",
    button_title:'Select Image File',
    function: (e) => remove_bg_img(e),
  },
  {
    name: "convert_to_jpg",
    icon: require('../assets/convert_to_jpg.jpeg'),
    title: "Convert to JPG",
    description: "Convert multiple PNG to JPG (JPEG) image Format Free, Fast and Easy For all OS - AFoxlife. Click Now",
    file_types: "",
    output_format:'jpg',
    tool_type: "convert",
    enable_file_types: "",
    button_title:'Select Image File',
    function: (e) => convert_to_jpg(e),
  },
  // {
  //   name: "convert_from_jpg",
  //   icon: require('../assets/convert_from_jpg.jpeg'),
  //   title: "Convert from JPG",
  //   description: "",
  //   file_types: "",
  //   tool_type: "convert",
  //   enable_file_types: "",
  //   function: (e) => word_to_pdf(e),
  // },
  // {
  //   name: "html_to_jpg",
  //   icon:require('../assets/html_to_jpg.jpeg'),
  //   title: "HTML to JPG",
  //   description: "",
  //   file_types: "",
  //   tool_type: "convert",
  //   enable_file_types: "",
  //   function: (e) => word_to_pdf(e),
  // },

];

export const ImageToolsTitles = [
 
  {
    name: "OPTIMIZE IMAGE",
    slug: "optimize_img",
  },
  {
    name: "CONVERT",
    slug: "convert",
  },
 
];
