import React from "react";
import { PdfTools } from "../content/PdfTools";
import { Menu } from "antd";
import { ImageTools } from "../content/ImageTools";

const ToolsTabs = ({item,isPDF}) => {
    const data=isPDF?(PdfTools.filter(tool=>tool?.tool_type===item?.slug)):(ImageTools.filter(tool=>tool?.tool_type===item?.slug))
  return (
    <div>
      <h2 className="font-medium text-[16px] my-3 text-blue-800">{item?.name}</h2>

      {data?.map((item) => (
        <Menu.Item key={item.name} style={{ marginTop: 10, backgroundColor: "white" }}>
          <a className="hover:underline hover:font-bold  font-semibold text-black" href={item.name}>
            {item?.title}➚
          </a>
        </Menu.Item>
      ))}
    </div>
  );
};

export default ToolsTabs;
